
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { UserModule } from '@/store';
import { LocalStorageKeys } from '@/constants';

const userModule = getModule(UserModule);

const ssoStorageKeys: string[] = [
  LocalStorageKeys.CurrentStore,
  LocalStorageKeys.TokenData,
  LocalStorageKeys.User
];

@Component
export default class SSOIndex extends Vue {
  // Get the cookie value by name
  private getCookie(cname: string): string {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  private async created(): Promise<void> {
    try {
      ssoStorageKeys.forEach(key => {
        const value = this.getCookie(key);

        // All the keys must be correctly set if the sso succeeded
        if (value === '') {
          throw new Error('Error occurred with sso, please try again...');
        }

        localStorage.setItem(key, value);

        if (key === LocalStorageKeys.TokenData) {
          userModule.setAccessToken(JSON.parse(value));
        } else if (key === LocalStorageKeys.User) {
          userModule.setCurrentUser({ ...JSON.parse(value), isSSO: true });
        } else if (key === LocalStorageKeys.CurrentStore) {
          userModule.setCurrentStore(JSON.parse(value));
        }
      });

      await this.$router.push({ name: 'home.root' });
    } catch (err) {
      alert(err);
      await this.$router.push({ name: 'public.login' });
    }
  }
}
